<template>
  <div>

    <el-table
      :data="tableData"
      class="tree-var-list"
      style="width: 100%; margin-bottom: 20px"
      row-key="id"
      :show-summary="metadata.showTotals"
      :summary-method="getTotals"
      default-expand-all>

      <el-table-column v-for="col in getMetadataColumns()" :key="col.id" :label="col.text" :width="getColWidth(col)" >
        <template #header>
          <a v-if="hasHeaderLink(col)" href="javascript:void(0);" v-on:click="openHeaderLink(col)" >{{ col.text }}</a >
          <span v-if="!hasHeaderLink(col)">{{ col.text }}</span>
        </template>
        <template #default="scope">
          <cc-var-control
                :readOnly="readOnly"
                :metadata="col"
                :entity="scope.row"
                :property="col.id"
                :rootEntity="rootEntity"
                :onchanged="getOnChangedFunction(col, scope.row)"
                :style="getWidth(col)"
                @linkMethodCallback="emitLinkMethodCallback"
              />
        </template>
      </el-table-column>

      <el-table-column v-if="hasAddItemButton || hasDeleteButton" align="right">
        <template #default="scope">
          <div style="display: flex; justify-content: flex-end; gap: 10px; width: 100%;">
            <!-- NOTE: For other types of objects that use this control need to implement different 'newButtonFormula' -->
            <div v-if="hasAddItemButton && metadata && metadata.newButtonFormula == 'method:addChidComponent()'">
              <el-button
                @click="onAddChildComponentClicked(scope.row)"
                type="primary"
                :plain="isPlainButton"
                :icon="Plus"
                size="small"
              ></el-button>
            </div>

            <div v-if="hasDeleteButton">
              <el-button
                @click="removeItem(scope.row)"
                type="danger"
                :plain="isPlainButton"
                :icon="Delete"
                size="small"
              ></el-button>
            </div>
          </div>
        </template>
      </el-table-column>

    </el-table>

    <el-dialog :title="translate('Add')" v-model="addChildComponentVisible">
      <cc-var-group
        :metadata="{ columns: [metadata.newItemColumns, [] ]}"
        :entity="currentSelectComponent"
        :rootEntity="rootEntity"
        :showTitle="false"
        :singleColumn="true" />
      <template v-slot:footer>
        <span class="dialog-footer">
          <el-button @click="addChildComponentVisible=false">Close</el-button>
          <el-button v-if="!readOnly" type="primary" @click="addChildComponentAndClose" >OK</el-button>
        </span>
      </template>
    </el-dialog>

    <el-button
      v-if="hasAddItemButton && metadata && metadata.newButtonFormula == 'method:addChidComponent()'"
      @click="onAddChildComponentClicked(null)"
      :type="listButtonType"
      size="default"
      style="margin-top: 8px"
      :icon="Plus"
    ></el-button>
  </div>
</template>

<script>
import { markRaw } from 'vue'

import {
  Edit,
  //ArrowUp,
  //ArrowDown,
  Delete,
  ArrowRightBold,
  ArrowDownBold,
  Plus,
} from '@element-plus/icons-vue'

//import Vue from 'vue'
import { mapActions, mapGetters } from 'vuex'

export default {
  data() {
    return {
      Edit: markRaw(Edit),
      //ArrowUp: markRaw(ArrowUp),
      //ArrowDown: markRaw(ArrowDown),
      Delete: markRaw(Delete),
      ArrowRightBold: markRaw(ArrowRightBold),
      ArrowDownBold: markRaw(ArrowDownBold),
      Plus: markRaw(Plus),
      addChildComponentVisible: false,
      parentElement: undefined,
      currentSelectComponent: {}
    }
  },
  name: 'CcTreeVarList',
  props: {
    rootEntity: {},
    items: null,
    metadata: {},
    newItemTemplate: {},
    sortKey: '',
    readOnly: false,
    showRowBorders: false
    },
  computed: {
    ...mapGetters(['globalMetadata']),

    listButtonType() {
      var appSkin = this.globalMetadata ? this.globalMetadata.appSkin : null
      if (appSkin && appSkin.styles['--list-button-type']) {
        return appSkin.styles['--list-button-type']
      }
      return 'default'
    },

    isPlainButton() {
      return this.listButtonType != 'primary'
    },

    hasDeleteButton() {
      return !this.readOnly
    },

    hasAddItemButton() {
      return !this.readOnly && this.metadata
    },
    
    tableData() {
      return this.getSortedItemsRecursively(this.items)
    },
  },
  methods: {

    mounted() {},

    getMetadataColumns() {
      return this.getActualColumns(null)
    },

    getItemColumns(item) {
      var columns = this.getActualColumns()
      var result = []

      columns.forEach((colMeta) => {
        var hierObj = this.findHierObjectAndPropertyName(item, colMeta.id)
        result.push({
          meta: colMeta,
          entity: hierObj.entity,
          propName: hierObj.propName,
        })
      })

      return result
    },

    emitLinkMethodCallback(method,args) {
      this.$emit('linkMethodCallback', method, args)
    },

    getTotals() {
      var result = []

      const sumTotal = function(column, items) {
        if(items) {
          var res = 0
          items.forEach(n => res += (n[column.id] || 0) + (n.children && n.children.length>0 ? sumTotal(column, n.children) : 0))
          return res
        }
      };

      var metadataColumns = this.getMetadataColumns();
      if(metadataColumns) {
        metadataColumns .forEach(c => {
          switch(c.type) {
            case 'pricePerDurationUnits':
              result.push(this.toUserPricePerDurationUnits(sumTotal(c, this.items)))
              break;
            case 'durationInUserUnits':
              result.push(this.toDurationInUserUnits(sumTotal(c, this.items)))
              break;
            case 'calculated':
              result.push(sumTotal(c, this.items))
              break;
            default:
              result.push('')
          }
        })
      }
      
      return result
    },

    getFooter() { 
      var columns = this.getActualColumns()
      var result = []

      const rootEntity = this.rootEntity
      
      columns.forEach(c => {
        if (c.footerFormula) {
          result.push({ value: eval(c.footerFormula) })
        } else { 
          result.push({value:''})
        }
       })
      return result
    },


    getActualColumns(item) {
      if (!this.metadata) {
        return []
      }

      var columns = this.metadata.columns
      if (this.metadata.altViews && this.metadata.altViews.length > 0) {
        for (var i = 0; i < this.metadata.altViews.length; i++) {
          var altView = this.metadata.altViews[i]
          if (
            this.shouldUseAltColumns(item, altView.formulaIf) &&
            altView.columns
          ) {
            columns = altView.columns
            break
          }
        }
      }

      return columns
    },

    shouldUseAltColumns(item, formulaIf) {
      var result = false
      const rootEntity = this.rootEntity
      try {
        //console.log(formulaIf)
        result = eval(formulaIf) === true
      } catch (e) {
        result = false
      }

      return result
    },

    hasHeaderLink(col) {
      return col.headerLinkFormula && col.headerLinkFormula.length > 0
    },

    openHeaderLink(col) {
      // const isFunc = col.headerLinkFormula.startsWith('(function')
      const rootEntity = this.rootEntity
      const items = this.items
      var result = null
      try {
        result = eval(col.headerLinkFormula)
      } catch (e) {
        console.error(e)
        result = null
      }

      if (result && result.length > 0)
        this.navigateToLink(result)
    },

    getSortedItemsRecursively(items, parent) {
      if(!items || items.length == 0)
        return []

      if (this.sortKey && this.sortKey.length > 1) {
        items.sort((a, b) => {
          return a[this.sortKey] > b[this.sortKey]
        })
      }

      items.forEach(n => {
        n.parent = parent
        if(n.children && n.children.length > 0) {
          this.getSortedItemsRecursively(n.children, n)
        }
      })

      return items;
    },

    onAddChildComponentClicked(parent) {
      this.parentElement = parent
      // reset search element
      this.currentSelectComponent = {component: {externalRefId: '', name: '', id: 0}, quantity: 1} 
      this.addChildComponentVisible = true
    },

    addChildComponentAndClose() { 
      var comp = this.currentSelectComponent.component
      comp.quantity = this.currentSelectComponent.quantity
      if(!comp.externalRefId || comp.externalRefId.length == 0) {
        return this.showApiError({message: "Please select product!"})
      }

      this.addChildComponentVisible = false

      if(comp.id==0)
        comp.id = this.getMinCompIdRecursively(this.items) - 1

      if(this.parentElement) {
        if(!this.parentElement.children) {
          this.parentElement.children = []
        }
        comp.idx = this.lastIdx(this.parentElement.children) + 1
        this.parentElement.children.push(comp)
        this.$emit('onItemAdded', comp, this.parentElement)
      } else {
        comp.idx = this.lastIdx(this.items) + 1
        this.items.push(comp)
        this.$emit('onItemAdded', comp, this.rootEntity)
      }
    },

    getMinCompIdRecursively(items) {
      var result = 0
      items.forEach(i => {
        if(i.id < result) {
          result = i.id
        }
        if(i.children && i.children.length>0) {
          var r = this.getMinCompIdRecursively(i.children)
          if(r<result)
            result = r
        }
      })
      return result
    },

    removeItem(item) {
      this.tryRemoveItemRecursively(this.items, item, this.rootEntity)

      if(this.metadata.deleteButtonFormula && this.metadata.deleteButtonFormula.length > 0) {
        const rootEntity = this.rootEntity
        try {
          eval(this.metadata.deleteButtonFormula)
        } catch (e) {
          //result = false
          console.error(e)
        }
      }
    },

    tryRemoveItemRecursively(children, item, parent){
      var children = children || []
      if (children.indexOf(item) >= 0) {
        var realIdx = children.indexOf(item)
        children.splice(realIdx, 1)
        this.$emit('onItemRemoved', item, parent)
        return true
      } else {
        for(var i =0; i < children.length; i++) {
          if(this.tryRemoveItemRecursively(children[i].children, item, children[i])) {
            return true
          }
        }
      }

      return false
    },

    lastIdx(items) { 
      var result = 0
      for (var i = 0; i < items.length; i++) {
        if (items[i].idx > result) {
          result = items[i].idx
        }
      }
      return result
    },

    getOnChangedFunction(meta, entry) { 
      if (meta.id == 'idx')
        return (entry, propName, val) => {
          this.onIdxChanged(entry, propName, val)
          if (meta.onChangeFormula)
            this.evalOnChangeFormula(entry, meta.onChangeFormula)
        }
        
      if (meta.onChangeFormula && meta.onChangeFormula.length > 0)
        return (entry, propName, val) => {
          // console.log(entry[propName])
          return this.evalOnChangeFormula(entry, meta.onChangeFormula)
        } 
        
      return undefined
    },
    
    onIdxChanged(entity, propName, val) {
      const min = 1
      if (val >= min)
        entity.idx = min
    },

    evalOnChangeFormula(item, formula) {
      //var result = undefined
      const rootEntity = this.rootEntity
      try {
        //console.log(formulaIf)
        //result = eval(formulaIf) === true
        eval(formula)
      } catch (e) {
        //result = false
        console.error(e)
      }

      //return result
    },

    edit(item) {
      this.$emit('onItemEdit', item)
    },

    getRowClass(entry) {
      var result = 'el-table-row-second-col-idx el-table-row-first-col-idx'

      return result
    },

    getColWidth(col) {
      if(col.type == 'calculatedHidden' || col.type == 'hidden')
        return '1px'

      if(col.width && col.width > 0)
        return `${col.width}px`

      return this.undefined
    },

    getWidth(cellMeta) {
      if(cellMeta.width && cellMeta.width > 0)
        return `width:${cellMeta.width}px`
      return ''
    },
  },
}
</script>

<style scoped>


tr > td:last-child {
  text-align: right;
}

.totals {
  font-size: 14px;
}

.negative {
  color: red;
}

.plain-button {
  border: none; /* Remove button border */
  padding: 0; /* Remove padding */
}
.plain-button .el-icon {
  font-size: 16px; /* Adjust icon size if needed */
}

.plain-button:hover {
  background-color: transparent !important;
  border-color: transparent !important;
  color: inherit !important;
}

</style>
