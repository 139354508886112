export default {
  translate(str, lang) {
    if (lang == 'EN') {
      return str
    }

    if (lang == 'BG') {
      return this.translateBG(str)
    }

    return str
  },

  translateBG(str) {
    switch (str) {
      case 'Password':
        return 'Парола'
      case 'Sign in':
        return 'Вписване'
      case 'Input your email':
        return 'Вашият email'
      case 'Input your password':
        return 'Вашата парола'
      case 'Login':
        return 'Вписване'
      case 'Demo Account':
        return 'Демо Акаунт'
      case 'Create Demo Account':
        return 'Създаване на Демо Акаунт'
      case 'Contact':
        return 'Контакт'
      case 'Comming soon':
        return 'Очаквайте скоро'
      case 'For demo account creation, please contact us at':
        return 'За създаване на демо акаунт се с нас на'
      case 'Successfully signed out':
        return 'Успешен изход.'
      default:
        return str
    }
  },
}
