<template>
  <div>
    <div v-if="getIsExpandable" :class="getTotalsClass()">
      <el-form-item label-width="0px">
        <el-button
          style="float: left"
          @click="setTotalsMinimized({ totalsMinimized: !totalsMinimized })"
          link
          :icon="getTotalsHideShowIcon()"
          size="small"
          >totals</el-button
        >

        <div
          class="totals-value-minimized"
          v-for="(item, itemIdx) in totals()"
          v-show="getIsMinimized && item.meta.type!='calculatedHidden'"
          :title="item.meta.text"
          :v-model="item.entity[item.propName]"
          :key="itemIdx"
        >
          {{ item.entity[item.propName] }} {{ item.meta.suffix }}
        </div>
      </el-form-item>

      <el-form-item
        v-for="(item, itemIdx) in totals()"
        :label="item.meta.text"
        label-width="240px"
        :key="itemIdx"
        v-show="item.meta.type!='calculatedHidden'"
      >
        <cc-var-control
          v-show="!totalsMinimized"
          :metadata="item.meta"
          :entity="item.entity"
          :property="item.propName"
          :rootEntity="rootEntity"
          additionalClass="totals-company-coeficient-input"
          :readOnly="readOnly"
        />
      </el-form-item>
    </div>

    <div v-if="!getIsExpandable" :class="getTotalsClass()">
      <div
        v-show="getIsMinimized"
        class="totals-value-minimized"
        v-for="(item, itemIdx) in totals()"
        :title="item.meta.text"
        :v-model="item.entity[item.propName]"
        :key="itemIdx"
      >
        {{ item.entity[item.propName] }} {{ item.meta.suffix }}
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { ArrowDown, ArrowUp } from '@element-plus/icons-vue'
import { markRaw } from 'vue'

/* eslint-disable */
export default {
  name: 'CcTotals',

  props: {
    metadata: {},
    entity: {}, // inquiry item or order item
    entities: {}, // list of items. Only one of 'entities' or 'entity' must be specified
    rootEntity: {}, // inquiry or order
    notExpandable: false,
    readOnly: false,
  },

  data() {
    return {
      ArrowUp: markRaw(ArrowUp),
      ArrowDown: markRaw(ArrowDown),
    }
  },

  computed: {
    ...mapState({
      totalsMinimized: (state) => state.totalsMinimized,
    }),

    getIsMinimized() {
      return this.totalsMinimized || this.notExpandable
    },

    getIsExpandable() {
      return !this.notExpandable
    },
  },

  methods: {
    ...mapActions(['setTotalsMinimized']),

    mounted() {},

    totals() {
      var result = []

      var isSingle = !!this.entity && !!this.rootEntity
      var isList = !!this.entities

      if (!this.metadata || !(isSingle || isList)) {
        return result
      }

      for (var i = 0; i < this.metadata.length; i++) {
        var meta = this.metadata[i]

        if (isSingle) {
          var val = this.getTotalsValueSingle(meta)
          result.push(val)
        } else if (isList) {
          var val = this.getTotalsValueList(meta)
          result.push(val)
        }
      }

      return result
    },

    getTotalsValueSingle(meta) {
      var entity = this.entity
      var path = meta.id

      if (path.startsWith('rootEntity.')) {
        path = meta.id.substring('rootEntity.'.length)
        entity = this.rootEntity
      }

      var hierObj = this.findHierObjectAndPropertyName(entity, path)

      return { meta: meta, entity: hierObj.entity, propName: hierObj.propName }
    },

    getTotalsValueList(meta) {
      if (meta.type == 'calculated') {
        var value = this.getTotalsValueListCalculated(meta)
        return { meta: meta, entity: { value: value }, propName: 'value' }
      } else {
        console.error(`Unknown column type '${meta.type}' for list totals`)
      }
    },

    getTotalsValueListCalculated(meta) {
      var items = this.entities
      try {
        var val = eval(meta.formula)
        return val
      } catch (e) {
        console.error('ERROR: evaluateFormula:')
        console.error(e.message)
        return ''
      }
    },

    getTotalsHideShowIcon() {
      return this.totalsMinimized ? 'ArrowUp' : 'ArrowDown'
    },

    getTotalsClass() {
      var result = 'totals fixed '
      result += this.getIsMinimized ? 'totals-collapsed' : 'totals-expanded'
      return result
    },
  },
}
</script>

<style scoped></style>
