import { isArray } from 'lodash'

export default (http, store, router) => {
  // https://github.com/axios/axios#interceptors
  http.interceptors.response.use(
    (response) => response,
    /**
     * This is a central point to handle all
     * error messages generated by HTTP
     * requests
     */
    (error) => {
      const { response } = error

      if(!response) {
        // console.log(error.toString())
        store.dispatch('setFetching', { fetching: false })
        return Promise.reject(error)
      }
      /**
       * If token is either expired, not provided or invalid
       * then redirect to login. On server side the error
       * messages can be changed on app/Providers/EventServiceProvider.php
       */
      if ([401 /*, 400*/ , 403].indexOf(response.status) > -1) {
        router.push({ name: 'auth.signin' }).catch(() => {})
      } /*else if ([403].indexOf(response.status) > -1) {
        //console.log('40003')
        store.dispatch('setMessage', {
          type: 'error',
          message: ['Access denied.'],
        })
      }*/
      
      /**
       * Error messages are sent in arrays
       */
      if (isArray(response.data)) {
        store.dispatch('setMessage', {
          type: 'error',
          message: response.data.messages,
        })
        /**
         * Laravel generated validation errors are
         * sent in an object
         */
      } else {
        store.dispatch('setMessage', {
          type: 'validation',
          message: response.data,
        })
      }

      store.dispatch('setFetching', { fetching: false })

      return Promise.reject(error)
    }
  )
}