<template>
  <div id="app">
    <cc-nav-bar v-show="shouldShowNavigation"></cc-nav-bar>
    <cc-alerts></cc-alerts>
    <div class="container">
      
      <router-view v-slot="{ Component, route }">
        
      <transition name="fade">
        <component :is="Component" :key="route.path" />
      </transition>
      
    </router-view>
    </div>
  </div>
</template>


<script>
// import { mapGetters } from 'vuex'
import CcNavBar from './components/root/navbar.vue'
import CcAlerts from './components/general/alerts.vue'

// todo - try to remove this - only some stile issues pending for the grid!!!
import 'bootstrap-sass/assets/stylesheets/_bootstrap.scss'

import 'element-plus/theme-chalk/index.css'

import './assets/sass/prodexis.css'
import { useRoute } from 'vue-router'



export default {
  name: 'Prodexis',
  components: {
    CcNavBar,
    CcAlerts,
  },

  setup() {
    const route = useRoute()

    return {
      route
    }
  },

  methods: {},

  computed: {
    // ...mapGetters(['shouldShowNavigation', 'globalMetadata']),
    
    shouldShowNavigation() {
      var isAuth = this.route.path.indexOf('/auth') !== -1
      return this.route.path ? !isAuth : false
    },

    /*getAppStyle() {
      return this.globalMetadata ? this.globalMetadata.appStyle : null
    },*/
  },
}
</script>

<style scoped>
div.container {
  width: 100%;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.25s ease;
}
.fade-enter,
.fade-leave-active {
  opacity: 0;
}

</style>
