export const MAIN_SET_MESSAGE = 'MAIN_SET_MESSAGE'
export const MAIN_SET_FETCHING = 'MAIN_SET_FETCHING'
export const MAIN_SET_TOTALSMINIMIZED = 'MAIN_SET_TOTALSMINIMIZED'

export const CATEGORIES_SET_DATA = 'CATEGORIES_SET_DATA'

export const ORDERCONTROL_UPDATE_ORDER = 'ORDERCONTROL_UPDATE_ORDER'
export const ORDERCONTROL_SET_FILTER = 'ORDERCONTROL_SET_FILTER'

export const INQUIRIES_SET_DATA = 'INQUIRIES_SET_DATA'

export const ORDERS_SET_DATA = 'ORDERS_SET_DATA'

export const MATERIALORDERS_SET_DATA = 'MATERIALORDERS_SET_DATA'

export const EXTERNALCOOPERATIONS_SET_DATA = 'EXTERNALCOOPERATIONS_SET_DATA'

export const CUSTOMERS_SET_DATA = 'CUSTOMERS_SET_DATA'

export const PROVIDERS_SET_DATA = 'PROVIDERS_SET_DATA'

export const COMPONENTS_SET_DATA = 'COMPONENTS_SET_DATA'

export const SHOPFLOORCONTROL_SET_DATA = 'SHOPFLOORCONTROL_SET_DATA'

export const RESOURCES_SET_DATA = 'RESOURCES_SET_DATA'

export const CORPORATESETTINGS_SET_DATA = 'CORPORATESETTINGS_SET_DATA'

export const USERS_SET_DATA = 'USERS_SET_DATA'

export const ROLES_SET_DATA = 'ROLES_SET_DATA'

export const WORKCENTERS_SET_FILTER = 'WORKCENTERS_SET_FILTER'

export const ALERTS_SET_FILTER = 'ALERTS_SET_FILTER'
