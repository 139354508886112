// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Statements/import
import { routes as shopfloorcontrol } from './shopfloorcontrol'
import { routes as customers } from './customers'
import { routes as providers } from './providers'
import { routes as materialorders } from './materialorders'
import { routes as externalcooperations } from './externalcooperations'
import { routes as components } from './components'
import { routes as inquiries } from './inquiries'
import { routes as orders } from './orders'
import { routes as workcenters } from './workcenters'
import { routes as reports } from './reports'
import { routes as ordercontrol } from './ordercontrol'
import { routes as settings } from './settings'
import { routes as auth } from './auth'
import { routes as version } from './version'
import { routes as resources } from './resources'
import { routes as corporatesettings } from './corporatesettings'
import { routes as users } from './users'
import { routes as roles } from './roles'
import { routes as alerts } from './alerts'
import { routes as customerorders } from './customerorders'
import { routes as schedule } from './schedule'
import { routes as schedule_new } from './schedule_new'
import { routes as mobileapp } from './mobileapp'

const NotFoundPage = () => import('./notfoundpage/main.vue')

// https://developer.mozilla.org/pt-BR/docs/Web/JavaScript/Reference/Operators/Spread_operator
// Thus a new array is created, containing all objects that match the routes.
export default [
  ...auth,
  ...shopfloorcontrol,
  ...inquiries,
  ...orders,
  ...customers,
  ...providers,
  ...materialorders,
  ...externalcooperations,
  ...components,
  ...workcenters,
  ...ordercontrol,
  ...settings,
  ...version,
  ...resources,
  ...reports,
  ...corporatesettings,
  ...users,
  ...roles,
  ...alerts,
  ...customerorders,
  ...schedule,
  ...schedule_new,
  ...mobileapp,
  {
    name: 'catchall',
    path: '/:catchAll(.*)',
    component: NotFoundPage,
    meta: { requiresAuth: true },
  },
]