/* eslint-disable */
import {
  Signup,
  Signin,
  DemoAccount,
  Main,
  Signout,
  ResetPassword,
} from './components'

const children = [
  {
    name: 'auth.signin',
    path: 'signin',
    component: Signin,
    meta: { requiresAuth: false },
  },
  {
    name: 'auth.signup',
    path: 'signup',
    component: Signup,
    meta: { requiresAuth: false },
  },
  {
    name: 'auth.demoaccount',
    path: 'demoaccount',
    component: DemoAccount,
    meta: { requiresAuth: false },
  },
  {
    name: 'auth.signout',
    path: 'signout',
    component: Signout,
    meta: { requiresAuth: true },
  },
  {
    name: 'auth.resetpassword',
    path: 'resetpassword',
    component: ResetPassword,
    meta: { requiresAuth: false },
  },
]

export default [
  {
    children,
    name: 'auth',
    path: '/auth',
    component: Main,
    redirect: { name: 'auth.signin' },
    meta: { requiresAuth: false },
  },
]
