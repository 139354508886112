import store from '../store'
import { currentUser  } from '../app/auth/vuex/getters'

const needAuth = (auth) => auth === true

const beforeEach = (to, from, next) => {
  const auth = to.meta.requiresAuth
  //const role = to.meta.role
  

  /**
   * Clears all global feedback message
   * that might be visible
   */
  store.dispatch('resetMessages')

  /**
   * If route doesn't require authentication be normally accessed.
   */
  if (!needAuth(auth)) {
    next()
    return // return to prevent the code from continuing in its flow
    // With this flow `else` or `else if` is not necessary
  }

  /**
   * Otherwise  if authentication is required login.
   */
  store
    .dispatch('checkUserToken')
    .then(() => {
      if(to.path === '/') {
        var defaultMenuItem ;
        if(store.state.Auth.user.isShopFloorWorker) {
          next('/shopfloorcontrol') // can access the route
        } else if (defaultMenuItem = store.state.Auth.user.mainMenu.find(n => n.isDefault)) {
          next({ name: defaultMenuItem.id })
        } else {
          next()    
        }
      } else {
        next() // can access the route
      }
    })
    .catch(() => {
      // No token, or it is invalid
      next({ name: 'auth.signin' }) // redirect to login
    })
}

export default beforeEach
